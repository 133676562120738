body {
  font-family: 'Special Elite', monospace;
  line-height: 1.6;
  color: #333;
  background-color: #f4f4f4;
}

.blog-container {
  max-width: 800px;
  margin: 50px auto;
  padding: 80px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: relative;
}

.blog-title {
  font-size: 2.5rem;
  margin-bottom: 20px;
  color: #333;
}

.blog-author {
  font-size: 0.9rem;
  color: #777;
  margin-bottom: 40px;
}
.header-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: absolute;
  top: 20px;
  right: 20px;
}
.blog-logo {
  width: 150px; /* Adjust as needed */
  height: auto;
  margin-bottom: 10px;
}
.blog-content p {
  font-size: 1.1rem;
  line-height: 1.6;
  margin-bottom: 20px;
  color: #444;
}
.skip-button {
  position: absolute;
  top: 160px;
  left: 10px;
  padding: 10px 15px;
  background-color: #f4f4f4;
  border: 1px solid #ddd;
  border-radius: 4px;
  cursor: pointer;
  font-family: 'Special Elite', monospace;
  font-size: 0.7em;
  transition: background-color 0.3s ease;
}

.skip-button:hover {
  background-color: #e4e4e4;
}